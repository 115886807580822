import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { Observable, Observer, Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/custom/authentication.service';
import { NavigationService } from 'chronos-live-client';
import { UxpageUp } from 'chronos-live-client';
import { MatDialog } from '@angular/material/dialog';
import { ILocalization } from '../../services/custom/interface/ILocalization.interface';
import { UserCustomService } from '../../services/custom/user-custom.service';
import { MenuItem } from 'chronos-shared';
import { Router } from '@angular/router';
import { MenuLoadedService } from 'projects/chronos-shared/src/lib/services/menu/menu-loaded.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy, ILocalization {
  public itemSelect: number[] = [];
  public sideBarMenu: MenuItem[] = [];
  public menuItems$: Observable<MenuItem[]>;

  private activeSubscription: Subscription[] = [];
  public translationKeyPrefix: string;

  constructor(
    public menuItems: MenuItems,
    private authenticationService: AuthenticationService,
    private navigationService: NavigationService,
    public dialog: MatDialog,
    public userCustomService: UserCustomService,
    private router: Router,
    private menuLoadedService: MenuLoadedService
  ) {
    // eslint-disable-next-line import/no-deprecated
    this.translationKeyPrefix = 'Sidebar.MenuItem.';
  }

  public ngOnInit() {
    this.menuItems$ = new Observable<MenuItem[]>((observer) => {
      const currentSubjectSubscription = this.authenticationService.currentUserSubject.subscribe(() => {
        this.getSideNavigation(observer);
      });

      this.activeSubscription.push(currentSubjectSubscription);
    });
  }

  public ngOnDestroy(): void {
    this.activeSubscription.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public getSideNavigation(observer: Observer<MenuItem[]>) {
    this.navigationService.getNavigation().subscribe({
      next: (navigationList) => {
        this.sideBarMenu = [];
        navigationList.sort((a, b) => a.uporder - b.uporder);

        navigationList.forEach((item: UxpageUp) => {
          if (!item.upparent) {
            const menuItem: MenuItem = {
              link: item.uxurl,
              id: item.upname,
              tooltip: this.translationKeyPrefix + item.upname,
              icon: item.upicon,
              onClick: () => this.router.navigate([item.uxurl])
            };
            this.sideBarMenu.push(menuItem);
          }
        });
        observer.next(this.sideBarMenu);
        this.menuLoadedService.notifyMenuLoaded();
      }
    });
  }
}
