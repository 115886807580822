import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { WorkCenterMachineData, KpiIdentifier } from 'chronos-core-client';
import { WorkCenterCachedService } from 'chronos-core-client';
import { clone } from 'ramda';
import { Subscription, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { SpeedMonitorViewMode } from '../speed-monitor/models/speed-monitor.model';
import { WorkCenterMachineDataWithOrder, GridSettingGsWithSettingValue } from './WorkCenterMachineDataWithOrder';
import {
  GridSettingsService,
  GridRowSettings,
  GridColumnSettings,
  GridSettings,
  UserSettingType,
  OrganizationsService
} from 'chronos-live-client';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColumnInfo } from '../va-mat-table/actions/column-sorter/column-sorter.service';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from '../models/chart-type.model';
import { MachineListService } from './machine-list.service';

@Component({
  selector: 'lib-machine-list',
  templateUrl: './machine-list.component.html',
  styleUrls: ['./machine-list.component.scss']
})
export class MachineListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public screenMode: SpeedMonitorViewMode = SpeedMonitorViewMode.Small;

  public durationHandler: any;
  public machineList: WorkCenterMachineData[] = [];
  public machineListWithOrder: WorkCenterMachineDataWithOrder[] = [];
  public tempMachineList: WorkCenterMachineData[] = [];
  public activeWorkcenters: WorkCenterMachineData[] = [];
  public isMobileView = false;
  public dragulaName = 'machineListDragula';
  public isPersonalSetting = false;
  public isStandardSetting = false;
  public maxRowOrder = 0;
  public gridSettings: GridSettings = { rowOrder: [], columnDetails: [] };
  public isDragDisabled = true;
  public isDisableButtons = true;
  public columnInfo: ColumnInfo[] = [];
  public chartMode: string;
  public settingType: UserSettingType;
  public gridSettingByType: GridSettingGsWithSettingValue[] = [];
  public dataSource: MatTableDataSource<WorkCenterMachineDataWithOrder>;
  public machineIdClass = 'machine-id';
  public machineChartClass = 'machine-chart';
  public machineStatusClass = 'machine-status';
  public modifyButtonContainerClass = 'container';
  public displayedColumns: string[] = [
    'hidden',
    'name',
    'externalWorkCenterId',
    'activeProductionOrderCode',
    'customerName',
    'externalFinishedGoodArticleId',
    'finishedGoodArticleName',
    'operationType',
    'output1Kpi.value.value',
    'downtimeKpi.valueTime',
    'Machine Chart',
    'Speed Monitor',
    'Machine Status'
  ];

  public actualColumns: string[] = [
    'hidden',
    'name',
    'externalWorkCenterId',
    'activeProductionOrderCode',
    'customerName',
    'externalFinishedGoodArticleId',
    'finishedGoodArticleName',
    'operationType',
    'output1Kpi.value.value',
    'downtimeKpi.valueTime',
    'Machine Chart',
    'Speed Monitor',
    'Machine Status'
  ];

  public displayedColumnsNames: string[] = [];
  public readonly standardSetting = 1;
  public readonly personalSetting = 2;

  private subscriptions = new Subscription();

  constructor(
    private translate: TranslateService,
    private workCenterCachedService: WorkCenterCachedService,
    private router: Router,
    private gridSettingsService: GridSettingsService,
    private cdr: ChangeDetectorRef,
    private machineListService: MachineListService,
    private organizationsService: OrganizationsService
  ) {
    this.machineListWithOrder = [{} as WorkCenterMachineDataWithOrder];
    this.dataSource = new MatTableDataSource(this.machineListWithOrder);
    this.translate
      .get([
        'MACHINE_LIST.MACHINE_HIDDEN',
        'MACHINE_LIST.MACHINE_NAME',
        'MACHINE_LIST.MACHINE_ID',
        'MACHINE_LIST.ACTIVE_JOB',
        'MACHINE_LIST.CUSTOMER_NAME',
        'MACHINE_LIST.ARTICLE_ID',
        'MACHINE_LIST.ARTICLE_NAME',
        'MACHINE_LIST.TECHNOLOGY',
        'MACHINE_LIST.OUTPUT_1',
        'MACHINE_LIST.DOWNTIME',
        'MACHINE_LIST.MACHINE_CHART',
        'MACHINE_LIST.SPEED_MONITOR',
        'MACHINE_LIST.MACHINE_STATUS'
      ])
      .subscribe((values) => {
        this.displayedColumnsNames = Object.keys(values).map((key) => values[key]);
      });
  }

  public ngOnInit(): void {
    this.settingType = this.personalSetting;
    this.getAllMachineDetails();
    this.isStandardSettingPresent(this.standardSetting); // usersettingtype Standard
    this.isStandardSettingPresent(this.personalSetting); // usersettingtype Personal
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;

    if (window.screen.width <= 700) {
      this.isMobileView = true;
      this.setMobileViewColumns();
    } else {
      this.isMobileView = false;
    }
    this.cdr.detectChanges();
  }

  public isStandardSettingPresent(settingType: UserSettingType) {
    forkJoin([
      this.gridSettingsService.isUserAdminRole({
        settingType
      }),
      this.gridSettingsService.isUserSettingPresent({
        gmname: this.dragulaName,
        settingType
      })
    ]).subscribe((results) => {
      if (results[0] && results[1]) {
        this.isStandardSetting = true;
      } else if (results[1] && settingType === this.personalSetting) {
        this.isPersonalSetting = true;
      }
    });
  }

  public getAllMachineDetails() {
    this.organizationsService.getActiveOrganizationsForUser({ orid: 0 }).subscribe((workCenters) => {
      if (workCenters) {
        this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
          if (result) {
            const ids = result.map((wcmd) => wcmd.id);
            this.workCenterCachedService.preloadWorkCenters(ids).subscribe(() => {
              workCenters.forEach((activeWorkcenter) => {
                result.forEach((workcenterelement) => {
                  if (activeWorkcenter.orname === workcenterelement.externalWorkCenterId) {
                    this.activeWorkcenters.push(workcenterelement);
                  }
                });
              });
              const machineList = this.activeWorkcenters.filter((v, i, a) => a.indexOf(v) === i);
              this.tempMachineList = machineList;
              this.getGridSettings(machineList, this.settingType);
              this.getAllGridSettingTypes();
              this.isStandardSelected(this.settingType);
              this.columnInfo.forEach((elementtype, index) => {
                const orderSubscription = this.machineListService
                  .getActiveProductionOrderNotifications(Number(elementtype.id))
                  .subscribe((orderresult) => {
                    if (orderresult) {
                      machineList[index].customerName = orderresult.customers[0].customerName;
                      machineList[index].activeExternalProductionOrderId = orderresult.externalProductionOrderId;
                      if (orderresult.finishedGoodArticles.length > 0) {
                        machineList[index].finishedGoodArticleName = orderresult.finishedGoodArticles[0].articleName;
                        machineList[index].externalFinishedGoodArticleId = orderresult.finishedGoodArticles[0].externalArticleId;
                      }
                    }
                  });
                this.subscriptions.add(orderSubscription);
                const activeProductionOrderId = this.machineList.filter((activeId) => {
                  if (activeId.id === Number(elementtype.id)) {
                    return activeId;
                  }
                });
                const kpiSubscription = this.machineListService
                  .getKpiNotifications(activeProductionOrderId[0]?.activeProductionOrderId)
                  .subscribe((kpiResult) => {
                    if (kpiResult) {
                      if (kpiResult.kpiIdentifier === KpiIdentifier.Downtime) {
                        machineList[index].downtimeKpi.value = kpiResult.value;
                      } else if (kpiResult.kpiIdentifier === KpiIdentifier.Output1) {
                        machineList[index].output1Kpi.value = kpiResult.value;
                      }
                    }
                  });
                this.subscriptions.add(kpiSubscription);
              });
            });
          }
        });
        // this.durationHandler = setTimeout(() => this.getAllMachineDetails(), 30000);
      }
    });
  }

  public getAllGridSettingTypes() {
    const observable = this.gridSettingsService.getGridSettingTypesByUser({
      gmname: this.dragulaName
    });
    observable.subscribe((result) => {
      this.gridSettingByType = result;
      result.forEach((elementtype, index) => {
        if (elementtype.settingType === this.standardSetting) {
          this.gridSettingByType[index].settingValue = 'Standard';
        } else {
          this.gridSettingByType[index].settingValue = 'Personal';
        }
      });
    });
  }

  public onChangeSettingType(settingType: UserSettingType) {
    this.getGridSettings(this.machineList, settingType);
    this.isStandardSelected(this.settingType);
  }

  public isStandardSelected(settingType: UserSettingType) {
    const observable = this.gridSettingsService.isUserAdminRole({
      settingType
    });
    observable.subscribe((result) => {
      if (!result && settingType === this.standardSetting) {
        this.isDisableButtons = true;
      } else {
        this.isDisableButtons = false;
      }
    });
  }

  public getGridSettings(machineList: WorkCenterMachineDataWithOrder[], settingType: UserSettingType) {
    let gridRows: GridRowSettings[] = [];
    let gridColumns: GridColumnSettings[] = [];

    const indexedRowArray: { [key: number]: number } = {};
    const hiddenRowArray: { [key: number]: boolean } = {};

    const observable = this.gridSettingsService.getGridSettingsByUser({
      gmname: this.dragulaName,
      settingType
    });
    observable.subscribe((result) => {
      if (result) {
        if (result.rowOrder) {
          gridRows = result.rowOrder;
        }
        if (result.columnDetails) {
          gridColumns = result.columnDetails;
          this.dataSource.data.map((machine, index) => {
            machine.rowOrder = index;
            return machine;
          });
          this.displayedColumns = [];
          gridColumns.map((column) => {
            if (!column.hidden && column.id !== 'hidden') {
              this.displayedColumns.push(column.id);
            }
          });
          this.setMobileViewColumns();

          this.columnInfo = gridColumns.map((column) => {
            const columnDetails: ColumnInfo = {
              id: column.id,
              name: column.name,
              hidden: column.id === 'hidden' ? true : column.hidden
            };
            return columnDetails;
          });
        }

        if (gridRows !== null) {
          for (const s of gridRows) {
            if (s.id) {
              indexedRowArray[s.id] = s.order;
              hiddenRowArray[s.id] = s.hidden;
            }
          }
        }

        machineList.forEach((machine) => {
          machine.rowOrder = indexedRowArray[machine.id];
          machine.hidden = hiddenRowArray[machine.id];
          return machine;
        });

        this.machineListWithOrder = machineList;
        this.machineListWithOrder = this.machineListWithOrder.sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1));
        this.dataSource.data = this.machineListWithOrder.filter((x) => !x.hidden);
        this.gridSettings.rowOrder = this.getGridRowOrder();
      } else {
        this.maxRowOrder = Math.max(...machineList.map((o) => o.rowOrder ?? -1));
        this.machineListWithOrder = machineList.map((machine) => {
          machine.rowOrder = machine.rowOrder ?? ++this.maxRowOrder;
          machine.hidden = false;
          return machine;
        });
        this.machineListWithOrder = machineList;
        this.machineListWithOrder = this.machineListWithOrder.sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1));
        this.dataSource.data = this.machineListWithOrder.filter((x) => !x.hidden);
        this.gridSettings.rowOrder = this.getGridRowOrder();

        this.displayedColumns = [];
        this.actualColumns.map((column) => {
          if (column !== 'hidden') {
            this.displayedColumns.push(column);
          }
        });
        this.setMobileViewColumns();

        this.columnInfo = this.actualColumns.map((currElement, index) => ({
          id: currElement,
          name: this.displayedColumnsNames[index],
          hidden: currElement === 'hidden'
        }));
      }
    });
  }

  public getGridRowOrder(): GridRowSettings[] {
    const gridRowSettings: GridRowSettings[] = [];
    this.dataSource.data.forEach((machine) => {
      const rowSettings: GridRowSettings = {};
      rowSettings.id = machine.id;
      rowSettings.order = machine.rowOrder;
      rowSettings.hidden = machine.hidden;
      gridRowSettings.push(rowSettings);
    });

    const unmappedRecords = this.machineListWithOrder.filter((x) => !this.dataSource.data.some((y) => y.id !== x.id));
    if (unmappedRecords) {
      unmappedRecords.forEach((machine) => {
        const rowSettings: GridRowSettings = {};
        rowSettings.id = machine.id;
        rowSettings.order = machine.rowOrder;
        rowSettings.hidden = machine.hidden;
        gridRowSettings.push(rowSettings);
      });
    }

    return gridRowSettings;
  }

  public enableDragDropMode() {
    if (window.screen.width <= 700) {
      this.isMobileView = true;
      this.setMobileViewColumns();
    } else {
      this.isMobileView = false;
    }

    this.isDragDisabled = false;
    this.columnInfo.map((currElement) => {
      if (currElement.id === 'hidden') {
        currElement.hidden = false;
      }
      return currElement;
    });

    this.machineListWithOrder = this.machineListWithOrder.sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1));
    this.dataSource.data = this.machineListWithOrder;
  }

  public disableDragDropMode() {
    this.isDragDisabled = true;
    this.columnInfo.map((currElement) => {
      if (currElement.id === 'hidden') {
        currElement.hidden = true;
      }
      return currElement;
    });
    this.getAllMachineDetails();
  }

  public onColumnChange(event: ColumnInfo[]) {
    const gridColumnSettings = event;
    this.gridSettings.columnDetails = gridColumnSettings;
  }

  public onRowDrop(event: CdkDragDrop<WorkCenterMachineDataWithOrder[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.dataSource.data = clone(this.dataSource.data);
      this.dataSource.data.map((machine, index) => {
        machine.rowOrder = index;
        return machine;
      });

      this.gridSettings.rowOrder = this.getGridRowOrder();
    }
  }

  public onHiddenCheck(row: WorkCenterMachineDataWithOrder, event: any) {
    this.dataSource.data.map((dataElement) => {
      if (dataElement.id === row.id) {
        dataElement.hidden = event.checked;
      }
      return dataElement;
    });

    this.gridSettings.rowOrder = this.getGridRowOrder();
  }

  public onSaveDragDropChanges() {
    this.saveGridSettings(this.gridSettings);
  }

  public onSaveDragDropChangesAndClose() {
    this.saveGridSettings(this.gridSettings);
    this.isDragDisabled = true;
  }

  public openMachineChart(row) {
    this.chartMode = ChartType.fullView;
    this.router.navigateByUrl(`/machineChart/${row.externalWorkCenterId}`, {
      state: {
        workCenterId: row.id,
        flag: true,
        isFullViewMode: true,
        isDetailedView: true,
        selectedMachineData: row,
        chartMode: this.chartMode,
        externalWorkCenterId: row.externalWorkCenterId,
        fromDashboardMachineList: true,
        showWasteAssignmentsSeries: true
      }
    });
  }

  public openSpeedMonitor(row) {
    this.router.navigateByUrl(`/speedmonitor/${row.externalWorkCenterId}`, {
      state: {
        workCenterId: row.id,
        flag: true,
        externalWorkCenterId: row.externalWorkCenterId
      }
    });
  }

  public setMobileViewColumns() {
    if (this.isMobileView) {
      this.displayedColumns = ['externalWorkCenterId', 'Machine Chart', 'Machine Status'];
      this.machineIdClass = 'machine-id-mobile';
      this.machineStatusClass = 'machine-status-mobile';
      this.machineChartClass = 'machine-chart-mobile';
      this.modifyButtonContainerClass = 'modify-button-mobile';
    } else {
      this.machineIdClass = 'machine-id';
      this.machineChartClass = 'machine-chart';
      this.machineStatusClass = 'machine-status';
      this.modifyButtonContainerClass = 'container';
    }
  }

  public onResize(event) {
    if (event.target.innerWidth <= 700) {
      this.isMobileView = true;
      this.setMobileViewColumns();
    } else {
      this.isMobileView = false;
    }
  }

  /**
   * dragula drag event
   * @param event row event
   */
  public onDrop(event: WorkCenterMachineDataWithOrder[]) {
    // Change reordering
    event.map((row, index) => {
      row.rowOrder = index;
      return row;
    });

    this.machineListWithOrder.map((row) => {
      row.rowOrder = event.find((x) => x.id === row.id).rowOrder;
      return row;
    });

    this.machineListWithOrder = this.machineListWithOrder.sort((a, b) => (a.rowOrder > b.rowOrder ? 1 : -1));
    this.dataSource.data = this.machineListWithOrder;
    // Prepare settings json to save
    // this.saveGridSettings(this.machineListWithOrder);
  }

  public createStandardView() {
    const observable = this.gridSettingsService.saveGridSettings({
      gmname: this.dragulaName,
      body: this.gridSettings,
      settingType: this.standardSetting
    });

    observable.subscribe((result) => {
      if (result) {
        this.getAllMachineDetails();
        this.isStandardSetting = false;
      }
    });
  }

  public createPersonalView() {
    const observable = this.gridSettingsService.saveGridSettings({
      gmname: this.dragulaName,
      body: this.gridSettings,
      settingType: this.personalSetting
    });

    observable.subscribe((result) => {
      if (result) {
        this.getAllMachineDetails();
        this.isPersonalSetting = false;
      }
    });
  }

  public saveGridSettings(gridSettings: GridSettings) {
    const observable = this.gridSettingsService.saveGridSettings({
      gmname: this.dragulaName,
      body: gridSettings,
      settingType: this.settingType
    });

    observable.subscribe((result) => {
      if (result) {
        this.getAllMachineDetails();
      }
    });
  }

  /**
   * dragula drop event
   * @param event row event
   */
  public onDrag() {
    // drag event here
  }

  public ngOnDestroy() {
    clearTimeout(this.durationHandler);
    this.subscriptions.unsubscribe();
  }
}
