<div class="traceability">
  <section>
    <div class="container">
      <div class="container__header">
        <h1 class="container__title">{{ 'TRACEABILITY.TITLE' | translate }}</h1>
      </div>
      <div class="left-elements">
        <div class="left-element">
          <lib-dropdown
            [options]="traceabilitySearchModeOption"
            [(ngModel)]="selectedSearchMode"
            (ngModelChange)="onFilterChange()"
            [defaultLabel]= "'TRACEABILITY.FORWARD_SEARCH' | translate"
            class="left-element__input"
            [panelWidth]="'150px'"
            appendTo="body"
          ></lib-dropdown>
        </div>
        <div class="left-element">
          <input
            id="textSearch"
            type="text"
            class="left-element__search"
            pInputText
            [(ngModel)]="textQuery"
            [placeholder]="searchPlaceHolder | translate"
            (input)="onFilterChange()"
          />
        </div>
      </div>

      <div class="right-elements">
        <div class="right-element">
          <span class="col-form-label right-elements__label">
            {{ 'TRACEABILITY.PRODUCTION_TIME' | translate }}
          </span>
          <lib-calendar
            [showTime]="false"
            [(ngModel)]="searchStartDateTime"
            [maxDate]="maxDate"
            [inputStyle]="{ width: '100px' }"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            (ngModelChange)="onStartDateChange()"
          ></lib-calendar>
          <span class="right-element__hyphon">-</span>
          <lib-calendar
            [showTime]="false"
            [(ngModel)]="searchEndDateTime"
            [maxDate]="maxDate"
            [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
            [inputStyle]="{ width: '100px' }"
            (ngModelChange)="onEndDateChange()"
          ></lib-calendar>
        </div>
        <div class="right-element">
          <lib-button [label]="'TRACEABILITY.SUBMIT'" (clicked)="submitFilters()" [disabled]="isSubmitDisabled"></lib-button>
          <span class="right-element__hyphon"></span>
          <lib-button [label]="'TRACEABILITY.EXPORT'" (clicked)="export()" [disabled]="isExportDisabled"></lib-button>
        </div>
      </div>
    </div>
  </section>
  <section class="traceability-table" *ngIf="showTables; else noData">
    <ng-container>
      <lib-production-order-list
        [selectedSearchMode]="selectedSearchMode"
        [forwardSearchData]="forwardSearchData"
        [backwardSearchData]="backwardSearchData"
      ></lib-production-order-list>
    </ng-container>
  </section>

  <ng-template #noData>
    <span class="traceability__no-data">{{ translatedMessage | translate }}</span>
    &nbsp;
    <span *ngIf="textInput">{{ textInput }}</span>
  </ng-template>

  <div *ngIf="isNodeLoading$ | async">
    <lib-loading-indicator></lib-loading-indicator>
  </div>
</div>
