import { finalize } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import moment from 'moment-mini';
import { SelectItem } from 'primeng/api';
import { BackwardSearchBaseModel, ForwardSearchBaseModel, TraceabilitySearchModeOption } from '../../models/traceability.model';
import { BehaviorSubject, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TraceabilityMainService } from '../../services/traceability-main.service';
import { MenuLoadedService } from 'projects/chronos-shared/src/lib/services/menu/menu-loaded.service';

@Component({
  selector: 'lib-traceability',
  templateUrl: './traceability.component.html',
  styleUrls: ['./traceability.component.scss']
})
export class TraceabilityComponent implements OnInit, AfterViewInit {

  public traceabilitySearchModeOption: SelectItem[] = [];
  public selectedSearchMode: SelectItem;
  public searchStartDateTime: Date;
  public searchEndDateTime: Date;
  public textQuery? = '';
  public searchPlaceHolder = '';
  public maxDate: Date;
  public isNodeLoadingSubject = new BehaviorSubject<boolean>(false);
  public isNodeLoading$ = this.isNodeLoadingSubject.asObservable();
  public searchCode: string;
  public searchPeriod: string;
  public forwardSearchData: ForwardSearchBaseModel[];
  public backwardSearchData: BackwardSearchBaseModel[];
  public showTables = false;
  public textInput = '';
  isSubmitDisabled = true;
  isExportDisabled = true;
  translatedMessage = 'TRACEABILITY.NO_DATA_LOADED';

  constructor(private translateService: TranslateService, 
    private traceabilityMainService: TraceabilityMainService,
    private menuLoadedService: MenuLoadedService,
    private cdr: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.searchPlaceHolder = 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER';
    this.searchStartDateTime = moment(new Date()).subtract(1, 'month').toDate();
    this.searchEndDateTime = this.maxDate = new Date();
  }

  ngAfterViewInit() {
    const lang = this.translateService.currentLang;

    if(lang) {
      this.initializeTraceabilitySearchModeOptions();
    } else {

      // page load case the translation service does not return the translations immediately
      // so we need to wait for the translations to be loaded
      this.menuLoadedService.menuLoaded$.subscribe(() => {
        this.initializeTraceabilitySearchModeOptions();
        
        // corner case when the translation is not loaded
        if(this.traceabilitySearchModeOption[0].label === 'TRACEABILITY.FORWARD_SEARCH') {
          this.traceabilitySearchModeOption = [];
          this.onLoadSearchModeOptions();
        }
      });
    }
    
    this.cdr.detectChanges(); 
  }

  public onFilterChange(): void {
    this.searchPlaceHolder =
      this.selectedSearchMode === this.traceabilitySearchModeOption[0].value
        ? 'TRACEABILITY.FORWARD_SEARCH_PLACEHOLDER'
        : 'TRACEABILITY.BACKWARD_SEARCH_PLACEHOLDER';

    if (this.textQuery && this.textQuery.length > 0 && this.selectedSearchMode) {
      this.isSubmitDisabled = false;
    } else {
      this.isSubmitDisabled = true;
    }
  }

  public onStartDateChange(): void {
    const searchStartDateTime = this.searchStartDateTime > this.searchEndDateTime ? this.searchEndDateTime : this.searchStartDateTime;
    this.searchStartDateTime = moment(searchStartDateTime).startOf('day').toDate();
    console.info('searchStartTime:', this.searchStartDateTime);
  }

  public onEndDateChange(): void {
    const searchEndDateTime =
      this.searchStartDateTime < this.searchEndDateTime
        ? this.searchEndDateTime > this.maxDate
          ? this.maxDate
          : this.searchEndDateTime
        : this.searchStartDateTime;
    this.searchEndDateTime = moment(searchEndDateTime).startOf('day').toDate();
    console.info('searchEndTime:', this.searchEndDateTime);
  }

  public submitFilters(): void {
    if(!this.isSubmitDisabled) {
      this.showTables = false;
      this.showLoader();
      const { startTime, endTime } = this.normalizeStartAndIncrementEndDate();
      if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
        this.traceabilityMainService
          .getTraceabilityForwardSearch(startTime.toISOString(), this.textQuery, endTime.toISOString())
          .pipe(
            finalize(() => {
              this.hideLoader();
            })
          )
          .subscribe(
            (data) => {
              if (data && data.length > 0) {
                this.forwardSearchData = data;
                this.addForwardRowIndexes();
                this.showTables = true;
                this.isExportDisabled = false;
                this.textInput = null;
              } else {
                this.isExportDisabled = true;
                this.setTranslatedMessage('TRACEABILITY.NO_DATA_FOUND');
                this.setTextInput(this.textQuery);
              }
            },
            (error) => {
              this.setTranslatedMessage('TRACEABILITY.NO_DATA_FOUND');
              this.setTextInput(this.textQuery);
              console.info(error);
            }
          );
      } else {
        this.traceabilityMainService
          .getTraceabilityBackwardSearch(startTime.toISOString(), this.textQuery, endTime.toISOString())
          .pipe(
            finalize(() => {
              this.hideLoader();
            })
          )
          .subscribe(
            (data) => {
              if (data && data.length > 0) {
                this.backwardSearchData = data;
                this.addBackwardRowIndexes();
                this.showTables = true;
                this.isExportDisabled = false;
                this.textInput = null;
              } else {
                this.isExportDisabled = true;
                this.setTranslatedMessage('TRACEABILITY.NO_DATA_FOUND');
                this.setTextInput(this.textQuery);
              }
            },
            (error) => {
              this.setTranslatedMessage('TRACEABILITY.NO_DATA_FOUND');
              this.setTextInput(this.textQuery);
              console.info(error);
            }
          );
      }
    }
  }

  public export(): void {
    if(!this.isExportDisabled) {
      this.showLoader();
      const { startTime, endTime } = this.normalizeStartAndIncrementEndDate();
      if (this.selectedSearchMode === this.traceabilitySearchModeOption[0].value) {
        this.traceabilityMainService
          .getTraceabilityForwardSearchExport(startTime.toISOString(), this.textQuery, endTime.toISOString())
          .pipe(
            finalize(() => {
              this.hideLoader();
            })
          )
          .subscribe(
            (data) => {
              if (data) {
                this.downloadCSV(data.csvContent);
              }
            },
            (error) => {
              console.info(error);
            }
          );
      } else {
        this.traceabilityMainService
          .getTraceabilityBackwardSearchExport(startTime.toISOString(), this.textQuery, endTime.toISOString())
          .pipe(
            finalize(() => {
              this.hideLoader();
            })
          )
          .subscribe(
            (data) => {
              if (data) {
                this.downloadCSV(data.csvContent);
              }
            },
            (error) => {
              console.info(error);
            }
          );
      }
    }
  }

  private downloadCSV(csvData: string) {
    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.selectedSearchMode}_export_data.csv`;
      link.click();
    }
  }

  private showLoader(): void {
    this.isNodeLoadingSubject.next(true);
  }

  private hideLoader(): void {
    this.isNodeLoadingSubject.next(false);
  }

  private normalizeStartAndIncrementEndDate() {
    const startTime = moment(this.searchStartDateTime).startOf('day').toDate();
    const endTime = moment(this.searchEndDateTime).add(1, 'days').startOf('day').toDate();
    return { startTime, endTime };
  }

  private setTranslatedMessage(message: string): void {
    this.translatedMessage = message;
  }

  private setTextInput(input: string): void {
    this.textInput = input;
  }

  private addForwardRowIndexes() {
    this.forwardSearchData = this.forwardSearchData.map((order, index) => ({
      ...order,
      index: index + 1 // Assign a unique number starting from 1
    }));
  }

  private addBackwardRowIndexes() {
    this.backwardSearchData = this.backwardSearchData.map((line, index) => ({
      ...line,
      index: index + 1 // Assign a unique number starting from 1
    }));
  }

  private initializeTraceabilitySearchModeOptions(): void {

    this.traceabilitySearchModeOption = [
      { label: this.translateService.instant('TRACEABILITY.FORWARD_SEARCH'), value: TraceabilitySearchModeOption.ForwardSearch },
      { label: this.translateService.instant('TRACEABILITY.BACKWARD_SEARCH'), value: TraceabilitySearchModeOption.BackwardSearch }
    ];
    
    if (this.traceabilitySearchModeOption.length > 0 && !this.selectedSearchMode) {
      console.info('this.traceabilitySearchModeOption', this.traceabilitySearchModeOption);
      this.selectedSearchMode = this.traceabilitySearchModeOption[0].value;
    }
  }

  private onLoadSearchModeOptions(): void {
    timer(1000).subscribe(() => {
      this.initializeTraceabilitySearchModeOptions();
    });
  }

}
