import { Injectable } from '@angular/core';
import {
  AclAccessRequest,
  AclAccessRoleList,
  AclfunctionAf,
  AcloperationAo,
  AclroleAr,
  AclRoleService,
  AuthFunctionOperation,
  AuthWidgetOperation,
  PageAccessDetails,
  PageWidgetDetail,
  RoleDataAccessCustom,
  RoledataaccessRa,
  UxpageUp,
  UxwidgetUw,
  WidgetFunctionOperation
} from 'chronos-live-client';
import { Observable, of, ReplaySubject } from 'rxjs';
import { DrawerSidenavService } from './drawer-sidenav.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementService extends DrawerSidenavService<AclAccessRoleList> {
  public functionOperations$: ReplaySubject<Array<AuthFunctionOperation>>;
  public widgetsToAccess$: ReplaySubject<Array<AuthWidgetOperation>>;

  constructor(private aclRoleService: AclRoleService, private authenticationService: AuthenticationService) {
    super();
    this.functionOperations$ = new ReplaySubject<Array<AuthFunctionOperation>>(1);
    this.widgetsToAccess$ = new ReplaySubject<Array<AuthWidgetOperation>>(1);
  }

  public getAclAccessRoles(orid: number): Observable<AclAccessRoleList[]> {
    return this.aclRoleService.getAclAccessRoles({ orid });
  }

  public getRoleDataAccessByRole(arid: number): Observable<RoleDataAccessCustom> {
    return this.aclRoleService.getRoleDataAccessByRole({ arid });
  }

  public getAccessOfComponent(pageName: string): Observable<Array<PageAccessDetails>> {
    return this.aclRoleService.getAccessOfComponent({ pageName });
  }

  public getAllRolesByOrganization(orid: number): Observable<Array<AclroleAr>> {
    return this.aclRoleService.getAclRoleNamesByOrganization({ orid });
  }

  public checkAuthorization(functionName: string): Observable<boolean> {
    return this.aclRoleService.checkAuthorization({ afName: functionName });
  }

  public getAllFunctionNamesByWidget(uwid: number): Observable<Array<AclfunctionAf>> {
    return this.aclRoleService.getAllAclFunctionNamesByWidget({ uwid });
  }

  public getWidgetFunctionAndEmptyOperations(uwid: number): Observable<Array<WidgetFunctionOperation>> {
    return this.aclRoleService.getWidgetFunctionAndEmptyOperations({ uwid });
  }

  public getWidgetFunctionAndOperationsWithValue(uwid: number, arid: number): Observable<Array<WidgetFunctionOperation>> {
    return this.aclRoleService.getWidgetFunctionAndOperationsWithValue({
      arid,
      uwid
    });
  }

  public getAllAclPageNames(): Observable<Array<UxpageUp>> {
    return this.aclRoleService.getAllAclPagesNames();
  }

  public getAllAclOperations(): Observable<Array<AcloperationAo>> {
    return this.aclRoleService.getAllAclOperations();
  }

  public getAllAclWidgets(upids: Array<number>): Observable<Array<UxwidgetUw>> {
    return this.aclRoleService.getAllAclWidgets({ body: upids });
  }

  public getAllAclPageWidgets(upids: Array<number>): Observable<Array<PageWidgetDetail>> {
    return this.aclRoleService.getAllAclPageWidgets({ body: upids });
  }

  public getAllAclPageWidgetsWithRole(upids: Array<number>, arid: number): Observable<Array<PageWidgetDetail>> {
    return this.aclRoleService.getAllPageWidgetsOfRole({
      body: upids,
      arid
    });
  }
  public getApiWidgetOperationse(arid: number): Observable<PageWidgetDetail> {
    return this.aclRoleService.getApiWidgetOperations({ arid });
  }

  public getAllAclFunctions(): Observable<Array<AclfunctionAf>> {
    return this.aclRoleService.getAllAclFunctions();
  }

  public updateAclAccess(updatedItem: AclAccessRoleList): Observable<boolean> {
    return this.aclRoleService.updateAclAccessRole({ body: updatedItem });
  }

  public addAccess(newItem: AclAccessRequest): Observable<boolean> {
    return this.aclRoleService.addAccess({ body: newItem });
  }

  public updateAccess(newItem: AclAccessRequest): Observable<boolean> {
    return this.aclRoleService.updateAccess({ body: newItem });
  }

  public deleteAccess(arid: number): Observable<boolean> {
    return this.aclRoleService.deleteAccess({ arid });
  }

  public addRoleDataAccess(list: Array<RoledataaccessRa>): Observable<boolean> {
    return this.aclRoleService.addRoleDataAccess({ body: list });
  }

  public updateRoleDataAccess(item: RoleDataAccessCustom): Observable<boolean> {
    return this.aclRoleService.updateRoleDataAccess({ body: item });
  }

  public getAclAccessDataForEdit(arid: number, orid: number): Observable<Array<AclAccessRoleList>> {
    return this.aclRoleService.getAclAccessDataForEdit({
      arid,
      orid
    });
  }

  public getAccessData(arid: number, orid: number): Observable<AclAccessRequest> {
    return this.aclRoleService.getAccessData({
      arid,
      orid
    });
  }

  public getAclWidgets(): Observable<Array<UxwidgetUw>> {
    return this.aclRoleService.getAclWidgets();
  }

  public getRoleDataAccessList(): Observable<Array<RoleDataAccessCustom>> {
    return this.aclRoleService.getRoleDataAccessList();
  }

  public getRoleDataAccess(arid: number): Observable<Array<RoledataaccessRa>> {
    return this.aclRoleService.getRoleDataAccess({ arid });
  }

  /**
   * fetch auth function operation
   */
  public fetchAllAuthFunctionOperation(): void {
    if (this.authenticationService.currentUserValue) {
      this.aclRoleService.getAllAuthFunctionOperation().subscribe(
        (result) => {
          this.functionOperations$.next(result);
          this.functionOperations$.complete();
        },
        (error) => {
          // it seems that the user is not authenticated, so we need to logout the user on the Live authentication service
          this.authenticationService.logout();
          console.error('Error fetching auth function operation', error);
          this.functionOperations$.error(error);
        }
      );
    }
  }

  /**
   * fetch all auth widget operation
   */
  public fetchAllAuthWidgetOperation(): void {
    if (this.authenticationService.currentUserValue) {
      this.aclRoleService.getAllAuthWidgetFunctionOperation().subscribe(
        (result) => {
          this.widgetsToAccess$.next(result);
          this.widgetsToAccess$.complete();
        },
        (error) => {
          console.error('Error fetching auth widget operation', error);
          this.widgetsToAccess$.error(error);
        }
      );
    }
  }

  /**
   * check access authorization
   * @param path check a
   */
  public checkAccessAuthorization(functionName: string): Observable<boolean> {
    let isAuthorized = false;
    this.getFunctionOperations().subscribe(
      (result) => {
        if (result && result.length > 0) {
          if (result.some((item) => item.afFunc.toLowerCase() === functionName.toLowerCase() && item.access)) {
            isAuthorized = true;
          }
        }
      },
      (error) => {
        console.error('Error checking access authorization', error);
        isAuthorized = false;
      }
    );

    return of(isAuthorized);
  }

  public checkWidgetAccessAuthorization(widgetName: string): Observable<boolean> {
    let isAuthorized = false;
    this.getWidgetFunctionOperations().subscribe(
      (result) => {
        if (result && result.length > 0) {
          if (result.some((item) => item.uwname?.toLowerCase() === widgetName?.toLowerCase())) {
            isAuthorized = true;
          }
        }
      },
      (error) => {
        console.error('Error checking widget access authorization', error);
        isAuthorized = false;
      }
    );

    return of(isAuthorized);
  }

  /**
   * get function operations
   */
  private getFunctionOperations(): Observable<Array<AuthFunctionOperation>> {
    return this.functionOperations$.asObservable();
  }

  private getWidgetFunctionOperations(): Observable<Array<AuthWidgetOperation>> {
    return this.widgetsToAccess$.asObservable();
  }
}
