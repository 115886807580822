<div [ngClass]="modifyButtonContainerClass" (window:resize)="onResize($event)">
  <div class="modify-view-btns m-t-10">
    <ng-container action-bar-items>
      <button
        p-button
        class="p-button"
        va-mat-table-column-sorter
        [(columns)]="displayedColumns"
        [columnNames]="displayedColumnsNames"
        [saveName]="'example-table'"
        [columnInfo]="columnInfo"
        (columnInfoChange)="onColumnChange($event)"
        *ngIf="!isDragDisabled"
        [disabled]="isDisableButtons"
      >
        <div class="center-content">
          <mat-icon>build</mat-icon>
          <span>&nbsp; {{ 'MACHINE_LIST.COLUMN_OPTIONS' | translate }}</span>
        </div>
      </button>
      <div class="m-l-20 setting-type">
        <!-- mandatory container -->
        <mat-select [(ngModel)]="settingType" class="m-l-10" *ngIf="!isDragDisabled" (selectionChange)="onChangeSettingType($event.value)">
          <mat-option *ngFor="let org of gridSettingByType" [value]="org.settingType">{{ org.settingValue }}</mat-option>
        </mat-select>
      </div>

      <!-- mandatory container -->
      <button
        mat-button
        mat-raised-button
        *ngIf="!isDragDisabled && isStandardSetting"
        color="primary"
        class="m-l-10"
        (click)="createStandardView()"
      >
        {{ 'MACHINE_LIST.CREATE_STANDARD_VIEW' | translate }}
      </button>

      <button
        mat-button
        mat-raised-button
        *ngIf="!isDragDisabled && isPersonalSetting"
        color="primary"
        class="m-l-10"
        (click)="createPersonalView()"
      >
        {{ 'MACHINE_LIST.CREATE_PERSONAL_VIEW' | translate }}
      </button>
      <button
        mat-button
        mat-raised-button
        *ngIf="!isDragDisabled"
        color="primary"
        [disabled]="isDisableButtons"
        class="m-l-10"
        (click)="onSaveDragDropChanges()"
      >
        {{ 'MACHINE_LIST.SAVE' | translate }}
      </button>
      <button
        mat-button
        mat-raised-button
        *ngIf="!isDragDisabled"
        color="primary"
        [disabled]="isDisableButtons"
        class="m-l-15"
        (click)="onSaveDragDropChangesAndClose()"
      >
        {{ 'MACHINE_LIST.SAVE_AND_CLOSE' | translate }}
      </button>
      <button *ngIf="!isDragDisabled" mat-button mat-raised-button color="primary" class="m-l-15" (click)="disableDragDropMode()">
        {{ 'MACHINE_LIST.CANCEL' | translate }}
      </button>
      <button *ngIf="isDragDisabled" mat-button mat-raised-button color="primary" (click)="enableDragDropMode()">
        {{ 'MACHINE_LIST.MODIFY_VIEW' | translate }}
        <!-- <mat-icon>settings</mat-icon> -->
      </button>
    </ng-container>
  </div>
</div>
<va-mat-table>
  <mat-table
    [dataSource]="dataSource"
    cdkDropList
    [cdkDropListData]="dataSource.data"
    (cdkDropListDropped)="onRowDrop($event)"
    matSort
    [cdkDropListDisabled]="isDragDisabled"
    class="machine-list-border"
  >
    <ng-container matColumnDef="hidden">
      <mat-header-cell *matHeaderCellDef class="hidden header-cell">
        {{ 'MACHINE_LIST.MACHINE_HIDDEN' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="hidden">
        <mat-checkbox [checked]="element.hidden" (change)="onHiddenCheck(element, $event)"></mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="machine-name header-cell">
        {{ 'MACHINE_LIST.MACHINE_NAME' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="machine-name">
        {{ element.name }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="externalWorkCenterId">
      <mat-header-cell *matHeaderCellDef [ngClass]="machineIdClass" class="header-cell">
        {{ 'MACHINE_LIST.MACHINE_ID' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [ngClass]="machineIdClass">
        {{ element.externalWorkCenterId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="activeProductionOrderCode">
      <mat-header-cell *matHeaderCellDef class="active-order header-cell">
        {{ 'MACHINE_LIST.ACTIVE_JOB' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="active-order">
        {{ element.activeExternalProductionOrderId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="customerName">
      <mat-header-cell *matHeaderCellDef class="customer-name header-cell">
        {{ 'MACHINE_LIST.CUSTOMER_NAME' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="customer-name">
        {{ element.customerName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="externalFinishedGoodArticleId">
      <mat-header-cell *matHeaderCellDef class="item-id header-cell">
        {{ 'MACHINE_LIST.ARTICLE_ID' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="item-id">
        {{ element.externalFinishedGoodArticleId }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="finishedGoodArticleName">
      <mat-header-cell *matHeaderCellDef class="item header-cell">
        {{ 'MACHINE_LIST.ARTICLE_NAME' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="item">
        {{ element.finishedGoodArticleName }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="operationType">
      <mat-header-cell *matHeaderCellDef class="technology header-cell">
        {{ 'MACHINE_LIST.TECHNOLOGY' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="technology">
        {{ element.operationType }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="output1Kpi.value.value">
      <mat-header-cell *matHeaderCellDef class="output1 header-cell">
        {{ 'MACHINE_LIST.OUTPUT_1' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="output1">
        {{ element.output1Kpi?.value.value | number : '1.0-0' }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="downtimeKpi.valueTime">
      <mat-header-cell *matHeaderCellDef class="downtime header-cell">
        {{ 'MACHINE_LIST.DOWNTIME' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="downtime">
        {{ element.downtimeKpi?.valueTime | millisecondsToDuration }}
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Machine Chart">
      <mat-header-cell *matHeaderCellDef [ngClass]="machineChartClass" class="header-cell">
        {{ 'MACHINE_LIST.MACHINE_CHART' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [ngClass]="machineChartClass">
        <button mat-icon-button color="primary" class="icon-buttons" (click)="openMachineChart(row)">
          <mat-icon>bar_chart</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Speed Monitor">
      <mat-header-cell *matHeaderCellDef class="speed-monitor header-cell">
        {{ 'MACHINE_LIST.SPEED_MONITOR' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="speed-monitor">
        <button mat-icon-button class="icon-buttons" color="primary" (click)="openSpeedMonitor(row)">
          <mat-icon>tv</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Machine Status">
      <mat-header-cell *matHeaderCellDef [ngClass]="machineStatusClass" class="header-cell">
        {{ 'MACHINE_LIST.MACHINE_STATUS' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [ngClass]="machineStatusClass">
        <div class="machine-status-holder">
          <lib-speed-monitor [workCenterId]="row.id" [mode]="screenMode" class="machine-status"></lib-speed-monitor>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag></mat-row>
  </mat-table>
</va-mat-table>
