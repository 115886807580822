import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MachineListWidgetComponent } from './machine-list-widget/machine-list-widget.component';
import { ChronosSharedModule } from 'chronos-shared';

@NgModule({
  declarations: [MachineListWidgetComponent],
  imports: [CommonModule, ChronosSharedModule]
})
export class MachineListModule {}
