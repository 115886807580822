import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'chronos-live-client';
import { authorizeGuard } from 'chronos-shared';
import { map, tap } from 'rxjs/operators';
import { applicationPaths } from '../services/custom/application-path.constant';
import { globalVariable } from '../services/custom/authentication.constant';
import { AuthenticationService } from '../services/custom/authentication.service';

export const LiveAuthorizationGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router: Router = inject(Router);
  const authenticationService: AuthenticationService = inject(AuthenticationService);
  const navigationService: NavigationService = inject(NavigationService);

  // skip authorization for speedmonitor and machinechart; they should not require authorization as they are running user-less
  if (state && state.url && (state.url.indexOf('speedmonitor') > -1 || state.url.indexOf('machinechart') > -1)) {
    return true;
  }

  return authorizeGuard(route, state).pipe(
    tap((isValid) => {
      if (isValid) {
        const currentUser = authenticationService.currentUserValue;
        const relativePath = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
        if (currentUser) {
          sessionStorage.setItem(globalVariable.CurrentUserObject, JSON.stringify(currentUser));
          authenticationService.currentUserSubject.next(currentUser);
          return navigationService.validateNavigation({ navigationUrl: relativePath }).pipe(
            map((isValid) => {
              if (isValid) {
                return true;
              } else {
                router.navigate([applicationPaths.ActiveDirDenied], {
                  queryParams: { returnUrl: state.url }
                });
                return false;
              }
            })
          );
        } else {
          authenticationService.logout();
          router.navigate([applicationPaths.ActiveDirLogin], {
            queryParams: { returnUrl: state.url }
          });
          return false;
        }
      }
    })
  );
};

/*
  const currentUser = authenticationService.currentUserValue;
  const relativePath = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
  if (currentUser) {
    sessionStorage.setItem(globalVariable.CurrentUserObject, JSON.stringify(currentUser));
    authenticationService.currentUserSubject.next(currentUser);
    return navigationService.validateNavigation({ navigationUrl: relativePath }).pipe(
      map((isValid) => {
        if (isValid) {
          return true;
        } else {
          router.navigate([applicationPaths.ActiveDirDenied], {
            queryParams: { returnUrl: state.url }
          });
          return false;
        }
      })
    );
  } else {
    authenticationService.logout();
    router.navigate([applicationPaths.ActiveDirLogin], {
      queryParams: { returnUrl: state.url }
    });
    return false;
  }
};*/
