import { GeneralSettingsCustomService } from '../services/custom/general-settings-custom.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MenuItems } from '../shared/menu-items/menu-items';

import { SidenavService } from '../services/custom/sidenav.service';
import { MatSidenav } from '@angular/material/sidenav';
import { UserCustomService } from '../services/custom/user-custom.service';
import { AuthenticationService } from '../services/custom/authentication.service';
import { WcSettings } from 'chronos-live-client';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { StyleService } from '../services/custom/style.service';
import { HttpStateService } from '../modules/http-spinner/http-state.service';
/** @title Responsive sidenav */
@Component({
  selector: 'app-shell',
  templateUrl: 'shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, AfterViewInit {
  public dir = 'ltr';
  public settings: WcSettings = {};

  public filterBy: string[] = [];

  private activeSubscriptions: Subscription[] = [];

  @ViewChild('snav', { static: false }) public sidenav: MatSidenav;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private sidenavService: SidenavService,
    private userCustomService: UserCustomService,
    private authenticationService: AuthenticationService,
    private generalSettingService: GeneralSettingsCustomService,
    private styleService: StyleService,
    private httpStateService: HttpStateService
  ) {
    this.filterBy = this.httpStateService.getServiceUrls();
    this.getOrganizationSettings();
  }

  public ngOnInit(): void {
    this.userCustomService
      .getUsersettingdetails(this.authenticationService.currentUserValue.orid, this.authenticationService.currentUserValue.usid)
      .subscribe((result) => {
        if (result && result.uedata) {
          this.settings = JSON.parse(result.uedata);
          this.userCustomService.isIconOnly.next(true);
        }
      });

    this.generalSettingService.isRefreshList.subscribe((res) => {
      if (res) {
        const settingSubscription = this.generalSettingService
          .getGeneralSettings(this.authenticationService.currentUserValue.orid)
          .subscribe((result) => {
            if (result) {
              this.generalSettingService.organizationSettings.next(result);
            }
          });
        this.activeSubscriptions.push(settingSubscription);
      }
    });
  }

  public ngAfterViewInit() {
    this.sidenavService.setSidenav(this.sidenav);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * get organization settings
   */
  public getOrganizationSettings() {
    this.generalSettingService.getGeneralSettings(this.authenticationService.currentUserValue.orid).subscribe((result) => {
      if (result) {
        this.generalSettingService.organizationSettings.next(result);
      }

      this.styleService.setMainTheme(environment.theme);
    });
  }
}
