import { Injectable } from '@angular/core';
import { DrawerSidenavService } from './drawer-sidenav.service';
import { OrganizationOr, GeneralSettings, GeneralSettingsService } from 'chronos-live-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsCustomService extends DrawerSidenavService<OrganizationOr> {
  public organizationSettings = new Subject<OrganizationOr>();
  public isGeneralSettingAddedorUpdated = new Subject<boolean>();
  public isRefreshList = new Subject<boolean>();
  public isListLoaded = new BehaviorSubject<boolean>(false);
  public contentMargin = new BehaviorSubject<number>(null);

  constructor(private generalSettingsService: GeneralSettingsService) {
    super();
  }

  public getOrganizations(orid: number): Observable<Array<OrganizationOr>> {
    return this.generalSettingsService.getOrganizations2({ orid });
  }

  public getOrganizationswithGeneralSettings(orid: number): Observable<Array<OrganizationOr>> {
    return this.generalSettingsService.getOrganizationsWithGeneralSettings({ orid });
  }
  public getGeneralSettings(orid: number): Observable<OrganizationOr> {
    return this.generalSettingsService.getGeneralSettings({ orid });
  }

  public addorUpdateGeneralSettings(generalSettings: GeneralSettings): Observable<OrganizationOr> {
    return this.generalSettingsService.addOrUpdateGeneralSettings({ body: generalSettings });
  }

  public deleteGeneralSetting(orid: number): Observable<number> {
    return this.generalSettingsService.deleteGeneralSettings({ orid });
  }
}
