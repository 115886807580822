import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuLoadedService {
  private menuLoadedSubject: Subject<void> = new Subject<void>();
  public menuLoaded$: Observable<void> = this.menuLoadedSubject.asObservable();

  notifyMenuLoaded() {
    this.menuLoadedSubject.next();
  }
}