<div class="row no-gutters table-check-element">
  <div class="table-check-element__item table-check-element__item--expanded consumption-batch">
    <p-table [value]="orderBatchItem" [columns]="columns" class="consumption-batch-table">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let column of columns">
            <th [style.width]="column.width" [style.textAlign]="column.textAlign">{{ column.label | translate }}</th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [ngClass]="rowData.isVirtualContainer ? 'dummy-article-row' : ''">
          <td class="table-text">
            {{ rowData.consumptionDate | utcToLocal | date : 'dd.MM.yyyy' }}
          </td>
          <td class="table-text">
            <span *ngIf="rowData.isVirtualContainer" class="warning-icon">
              <i class="icon icon-warning-solid icon--warning"></i>
            </span>
            {{ rowData.containerIdentification }}
          </td>
          <td class="table-text text-right">{{ rowData.consumedQuantity | formatQuantity : true }}</td>
          <td class="table-text text-right">{{ rowData.whiteWaste | formatQuantity : true }}</td>
          <td class="table-text">
            {{ rowData.externalWarehouseId }}
          </td>
          <td class="table-text">
            {{ rowData.externalWarehouseLocationId }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [colSpan]="columns.length">{{ 'APP.NO_DATA_FOUND' | translate }}</td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template #noData>
      <span>-</span>
    </ng-template>
  </div>
</div>
