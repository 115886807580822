import { LoginUser, AuthService, UserUsDto, AuthResponse } from 'chronos-live-client';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from './_models/user';
import { Router } from '@angular/router';
import { applicationPaths } from './application-path.constant';
import { globalVariable } from './authentication.constant';
import { ConstantsService } from '../common/constants.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;

  private refreshTokenTimeout;
  private userSubject: Subject<User> = new BehaviorSubject<User>(null);

  constructor(private autoAuthService: AuthService, private router: Router, private constantService: ConstantsService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem(globalVariable.CurrentUserObject)));
  }

  public login(userName: string, password: string): Observable<User> {
    const observable = this.autoAuthService.authenticate({
      body: {
        usemail: userName,
        password
      }
    });

    observable.subscribe(
      (result) => {
        const user: User = {
          userId: result.userId,
          orid: result.orid,
          usid: result.usid,
          response: result.response,
          locale: result.locale
        };
        this.userSubject.next(user);
      },
      (error) => {
        console.error('Error calling MES Live! authentication service', error);
        this.userSubject.error(error);
      }
    );

    return this.userSubject.asObservable();
  }

  public get isSynAuthTokenExpired(): boolean {
    const synAuthExpiry = sessionStorage.getItem(globalVariable.SynAuthExpiry) ?? null;
    if (synAuthExpiry) {
      return this.constantService.CurrentUnixTimeWithBuffer <= Number(synAuthExpiry) ? false : true;
    }
    return true;
  }

  public getUserAuth(): Observable<AuthResponse> {
    return this.autoAuthService.getUserAuth();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public logout(): void {
    this.stopRefreshTokenTimer();
    sessionStorage.removeItem(globalVariable.CurrentUserObject);
    this.currentUserSubject.next(null);
  }

  public logoutAndRedirect(): void {
    this.logout();
    this.router.navigate([applicationPaths.ActiveDirLogout]);
  }

  public getUserDetails(usid: number, orid: number): Observable<UserUsDto[]> {
    return this.autoAuthService.getAllUserDetails({
      usid,
      orid
    });
  }

  public updatePassword(orid: number, password: string, usid: number): Observable<number> {
    return this.autoAuthService.changeUserPassword({
      orid,
      password,
      usid
    });
  }

  public updateUser(orid: number, us: UserUsDto): Observable<UserUsDto> {
    return this.autoAuthService.updateUserSettings({
      orid,
      body: us
    });
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
