import {
  ValidUserParam,
  UserUsDto,
  UserService,
  WcSettings,
  UsersettingUe,
  UsergrpUg,
  AclroleAr,
  LocaleLc,
  TimezoneTz
} from 'chronos-live-client';
import { DrawerSidenavService } from './drawer-sidenav.service';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserCustomService extends DrawerSidenavService<UserUsDto> {
  public isRefreshList = new Subject<boolean>();
  public isIconandText = new Subject<boolean>();
  public isIconOnly = new Subject<boolean>();
  public users = new Subject<UserUsDto>();
  constructor(private client: UserService) {
    super();
  }

  public getUsers(orid: number): Observable<any> {
    return this.client.getUsers({ orid });
  }

  public getUsersettingdetails(orid: number, usid: number): Observable<UsersettingUe> {
    return this.client.getUserSetting({
      orid,
      usid
    });
  }

  public addUser(user: UserUsDto): Observable<UserUsDto> {
    return this.client.addUser({
      body: user,
      orid: -1
    });
  }

  public addUserSettings(orid: number, usid: number, ueid: number, ue: WcSettings): Observable<UsersettingUe> {
    return this.client.addUserSettings({
      orid,
      usid,
      ueid,
      body: ue
    });
  }

  public updateUser(user: UserUsDto): Observable<UserUsDto> {
    return this.client.updateUser({
      body: user,
      orid: -1
    });
  }

  public updateUserSettings(orid: number, usid: number, ueid: number, ue: WcSettings): Observable<UsersettingUe> {
    return this.client.updateUserSetting({
      orid,
      usid,
      ueid,
      body: ue
    });
  }

  public deleteUser(orid: number, usid: number): Observable<number> {
    return this.client.removeUser({
      usid,
      orid
    });
  }

  public activeInactiveUser(orid: number, user: UserUsDto): Observable<number> {
    user.userrolesUr = null;

    return this.client.activeInactiveUser({
      body: user,
      orid
    });
  }

  public getUserGroups(orid: number): Observable<Array<UsergrpUg>> {
    return this.client.getUserGroups({ orid });
  }

  public getUserRoles(orid: number): Observable<Array<AclroleAr>> {
    return this.client.getUserRoles({ orid });
  }

  public getLanguages(orid: number): Observable<Array<LocaleLc>> {
    return this.client.getLanguages({ orid });
  }

  public getTimeZones(orid: number): Observable<Array<TimezoneTz>> {
    return this.client.getTimeZones({ orid });
  }

  /**
   * get user information
   * @param orId organizationId
   * @param userId userId
   */
  public getUser(orId: number, userId: number): Observable<UserUsDto> {
    return this.client.getUser({
      orid: orId,
      usid: userId
    });
  }

  public validUsername(orId: number, user: ValidUserParam): Observable<boolean> {
    return this.client.isValidUserName({
      orid: orId,
      body: user
    });
  }

  public validUserEmail(orId: number, user: ValidUserParam): Observable<boolean> {
    return this.client.isValidUserEmail({
      orid: orId,
      body: user
    });
  }
}
